import React from "react"
import { Link, StaticQuery, graphql, navigate } from "gatsby"
import Layout from "../components/Layout/layout"

import firebase from 'firebase/app';
import 'firebase/firestore'

import { Button, Form, FormGroup, FormFeedback, Input, Label,
  Row, Col,
  Container
} from 'reactstrap';
import "./JadeDetails.scss"
import _ from 'lodash';
import moment from 'moment';

import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/zh.json"));
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const JadeDetails = class JadeDetails extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      email: '',
      country: 'Hong Kong',
      address: '',
      remarks: '',
      isEmailEmpty: '',
      validate: {
        emailState: '',
        phoneState: ''
      },
    }

    this.handleChange = this.handleChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    // this.redirectToCheckout = this.redirectToCheckout.bind(this)
  }

  componentDidMount(){
    // this.setState({countrylist: countries.getNames('zh')})
    this.stripe = window.Stripe(process.env.GATSBY_STRIPE_PUBKEY)
    console.log("current env:", process.env.GATSBY_ENV_STATUS);

  }

  handleChange(evt) {
    // check it out: we get the evt.target.name (which will be either "email" or "password")
    // and use it to target the key on our `state` object with the same name, using bracket syntax
    this.setState({ [evt.target.name]: evt.target.value });
  }

  // async redirectToCheckout(docRefId) {
  //   const { error }  = await  this.stripe.redirectToCheckout({
  //     items: [
  //       {
  //         sku: process.env.GATSBY_ENV_STATUS === "production" ? this.props.pageContext.product.stripeProdSKU : this.props.pageContext.product.stripeTestSKU,
  //         quantity: 1
  //       },
  //     ],
  //     locale: "auto",
  //     customerEmail: this.state.email,
  //     // clientReferenceId: docRefId,
  //     // billingAddressCollection: 'auto',
  //     shippingAddressCollection: {
  //       allowedCountries: ['US', 'CA', 'AU', 'CN', 'FR', 'DE', 'GR', 'HK', 'HU', 'IN', 'ID', 'IE', 'IL', 'JP', 'KR', 'LU', 'MT', 'MY', 'NL', 'NZ', 'PH', 'RU', 'SG', 'ZA', 'CH', 'TH', 'AE', 'GB', ],
  //     },
  //     submitType: 'pay',
  //     successUrl: `${process.env.GATSBY_API_URL}/paymentSuccess`,
  //     cancelUrl: `${process.env.GATSBY_API_URL}`,
  //     // successUrl: `http://localhost:8000/bookingSuccess`,
  //     // cancelUrl: `http://localhost:8000/`,
  //   })
  //
  //   if (error) {
  //     console.warn("Error:", error)
  //   }
  // }

  validateEmail(e) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { validate } = this.state

    if(!e.target.value) {
      this.setState({isEmailEmpty: true})
      return
    }

    if (emailRex.test(e.target.value)) {
      validate.emailState = 'has-success'
    } else {
      validate.emailState = 'has-danger'
    }
    this.setState({ validate, isEmailEmpty: false })
  }

  onFormSubmit(event){
    console.log('in form submit');
    event.preventDefault()
    console.log(this.state);
    console.log('props', this.props);
    // this.redirectToCheckout()
    // navigate("/paymentProcess/")
    let product = this.props.pageContext.product

    let requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify({
        amount: product.price,
        product_name: product.name,
        order_no: `order-${moment().format()}`,
        name: this.state.name,
        email: this.state.email,
        country: this.state.country,
        address: this.state.address,
        remarks: this.state.remarks,
      })
    }

    // fetch('http://localhost:3000/unionPayment', requestOptions)
    // fetch('https://driveclub.hk/unionPayment', requestOptions)
    //   .then(response => response.json())
    //   .then(data => {
    //     console.log('res data', data);
    //     window.location.href = data.redirectUrl
    //   })
    alert("Oops something wrong with cove, you will be redirected to the homepage.")
    window.location.href = "https://covedrive.com/"

  }

  render(){
    let product = this.props.pageContext.product
    // console.log(product);
    let countrylist = countries.getNames('en')

    return(
      <>
        <div style={{overflowX:'hidden'}}>
          <Layout>
            <Container className="orderPage-container">
              <Row>
                <Col xs="12" sm="12" md="7" className="d-flex flex-column">
                  <p className="h5 pb-2">{product.name}</p>
                  <div className="text-center p-3">
                    <img className="product-image" src={require(`../images/products/${product.image}`)} alt=""/>
                  </div>
                  <h6 className="pt-1 label-text">價錢: </h6>
                  <p className="value-text">HKD {product.price}</p>
                  {/*<h6 className="pt-1 label-text">重量: </h6>
                  <p className="value-text">{product.weight}g</p>
                  <h6 className="pt-1 label-text">尺寸: </h6>
                  <p className="value-text">{product.size} cm</p>
                  <h6 className="pt-1 label-text">等級: </h6>
                  <p className="value-text">{product.condition}</p>*/}

                </Col>

                <Col xs="12" sm="12" md="5" className="">
                  <Form className="orderForm" onSubmit={this.onFormSubmit}>
                    <FormGroup>
                      <label>名稱*</label>
                      <Input
                        name="name"
                        placeholder="John Appleseed" type="text"
                        onChange={this.handleChange}
                        required
                      />
                    <FormFeedback>名稱不能留空</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <label>電郵地址*</label>
                      <Input
                        name="email"
                        placeholder="john.appleseed@qq.com" type="email"
                        onChange={this.handleChange}
                        required
                        valid={ this.state.validate.emailState === 'has-success' }
                        invalid={ this.state.validate.emailState === 'has-danger' }
                      />
                      <FormFeedback valid>Email 格式正確</FormFeedback>
                      <FormFeedback>{ this.state.isEmailEmpty ? "Email 不能留空" : "Email 格式錯誤" }</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <label>收貨地址*</label>
                      <Input
                        name="address"
                        placeholder="" type="textarea" rows="3"
                        onChange={this.handleChange}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="exampleSelect">國家</Label>
                      <Input
                        type="select"
                        name="country"
                        onChange={this.handleChange}
                        value={this.state.country}
                      >
                        {
                          countrylist != null ?
                          _.map(countrylist, (value, index)=>{
                            return (
                              <option key={index}>{value}</option>
                            )
                          })
                          : ""
                        }

                      </Input>
                    </FormGroup>

                    <FormGroup>
                      <label>特別要求/備註 (選填)</label>
                      <Input
                        name="remarks"
                        placeholder="" type="textarea" rows="4"
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                    <Button
                      className="submit-btn"
                      type="submit"
                    >
                      前往付款
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Container>
          </Layout>
        </div>
      </>
    )
  }
}

export default JadeDetails

// export default () => {
//   return (
//     <Layout>
//       <div>Hello blog post</div>
//     </Layout>
//   )
// }
